footer{
	background: #000000;
	color: #ffffff;
	padding-top: 110px;
	padding-bottom: 90px;
	text-align: center;

	h4{
		color: #ffffff;
		font-family: $butler-bold;
		text-align: center;
		font-size: 32px;
		display: inline-block;
		padding-bottom: 15px;
		margin-bottom: 30px;
		border-bottom: 7px solid $gold;
	}

	p{
		color: #ffffff;
		font-family: $roboto-light;
		text-align: center;
		font-size: 18px;

		a{
			color:#ffffff;

			&:hover{
				color:#ffffff;
			}
		}

		&.contact{
			font-size: 18px;
			margin-top: 50px;
			line-height: 40px;

			img{
				width: 32px;
				heigth: auto;
				display: inline-block;
				margin-right: 10px;
				margin-left: 20px;
			}
		}
	}

	img{
		max-width: 100%;
	}
}