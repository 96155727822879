h1 {
	text-align: center;
	color: #000000;
	font-size: 85px;
	margin-bottom: 40px;
}

p {
	text-align: center;
	color: #000000;
	font-size: 25px
}