//Fonts

$roboto-light: robotolight, sans-serif;
$roboto-medium: robotomedium, sans-serif;
$roboto-bold: robotobold, sans-serif;
$butler-bold: butlerbold, sans-serif;

@font-face {
    font-family: robotolight;
    src: url("assets/fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: robotomedium;
    src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: robotobold;
    src: url("assets/fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: butlerbold;
    src: url("assets/fonts/Butler_Bold.otf");
}



//Colors

$light-gold: #f7d7b7;
$gold: #feb46a;