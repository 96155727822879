#grids-start{
}

#guestbook{
	padding-top: 110px;
	padding-bottom: 90px;

	h3{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: left;
		color: #000000;
	}

	p{
		font-size: 16px;
		font-family: $roboto-light;
		text-align: left;
		color: #000000;
	}

	h4{
		font-size: 18px;
		font-family: $butler-bold;
		text-align: left;
		color: #000000;
		text-transform: uppercase;
		border-bottom: 1px solid #000000;
		padding-bottom: 10px;
	}

	a{
		font-size: 16px;
		font-family: $roboto-medium;
		text-align: right;
		float: right;
		color: #000000;
		text-transform: uppercase;
		text-decoration: underline;
	}

	#commentform{
		input, textarea{
			border-radius: 0px !important;
			border: 1px solid $light-gold !important;
			font-size: 18px !important;
			font-family: $roboto-light;
			width: 100%;
			background: $light-gold;
			padding: 10px;

				&:focus{
					outline:none !important;
					-webkit-box-shadow: none !important;
					box-shadow: none !important;
				}

				&::-webkit-input-placeholder {
					color: #000000  !important;
					font-size: 18px !important;
					font-family: $roboto-light;
				}
						 
				&:-moz-placeholder { /* Firefox 18- */
					color: #000000 !important;
					font-size: 18px !important;
					font-family: $roboto-light;
				}
						 
				&::-moz-placeholder {  /* Firefox 19+ */
					color: #000000  !important;
					font-size: 18px !important;
					font-family: $roboto-light;
				}
						 
				&:-ms-input-placeholder {  
					color: #000000  !important;
					font-size: 18px !important;
					font-family: $roboto-light;
				}
			}	

		textarea{
			height: 230px;
			background: #ffffff;
		}

		input[type=submit]{
			background: #000000;
			color: #ffffff;
			font-size: 16px;
			font-family: $roboto-medium;
			text-transform: uppercase;
			border: none !important;
			padding: 15px 25px;
			float: right;
			width: auto;
		}
	}

	#comments{
		padding-left: 30px;
		padding-top: 10px;

		.comment{
			background: #eeeeee;
			padding: 15px 25px;
			margin-bottom: 30px;
			position: relative;

			h6{
				font-size: 16px;
				font-family: $butler-bold;
				text-align: left;
				color: #000000;
			}

			p{
				font-size: 14px;
				font-family: $roboto-light;
				text-align: left;
				color: #000000;
			}

			&:after{
				content:'';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12.5px 20px 12.5px 0;
				border-color: transparent #eeeeee transparent transparent;
				position: absolute;
				left: -20px;
				top: 25px;
			}
		}
	}
}

#instagram{
	background: #eeeeee;
	padding-top: 100px;
	padding-bottom: 80px;

	h3{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: center;
		color: #000000;
	}

	img{
		max-width: 100%;
		heigth: auto;
		margin-bottom: 0px;
	}

	.img-featured-container {
			  overflow: hidden;
			  position: relative;
			}

			.img-featured-container .img-backdrop {
			  background: rgba(0, 0, 0, 0.2);
			  margin: 0;
			  padding: 0;
			  width: 100%;
			  height: 100%;
			  position: absolute;
			  z-index: 1;
			  opacity: 0;
			  transition: all 0.3s ease;
			}

			.img-featured-container:hover > .img-backdrop {
			  opacity: 1;
			}

			// center text horizontally and vertically on image hover
			.img-featured-container .description-container {
			  color: #fff;
			  font-size: 16px;
			  line-height: 1.2;
			  padding: 0 30px;
			  text-align: center;
			  line-height: 20px;
			  width: 100%;
			  position: absolute;
			  top: 50%;
			  left: 50%;
			  transform: translate(-50%, -50%);
			  transform-style: preserve-3d;
			  z-index: 2;
			  opacity: 0;
			  transition: all .2s ease;
			}

			.img-featured-container .description-container .fa-instagram {
			  font-size: 40px;
			}

			.img-featured-container .description-container p {
			  font-weight: 300;
			  margin-bottom: 0;
			}

			.img-featured-container:hover .description-container {
			  opacity: 1;
			}

			.img-featured-container .description-container .likes,
			.img-featured-container .description-container .comments {
			  margin: 0 5px;
			}

	p{
		font-size: 20px;
		font-family: $roboto-light;
		text-align: left;
		color: #000000;
		float: left;
		margin-top: 30px;

		img{
			width: 42px;
			heigth: auto;
			display: inline-block;
			margin-left: 0px;
			margin-bottom: 0;
		}
	}

	a{
		font-size: 16px;
		font-family: $roboto-medium;
		text-align: right;
		color: #000000;
		text-transform: uppercase;
		text-decoration: underline;
		float: right;
		margin-top: 30px;
	}
}