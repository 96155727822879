body{
	margin-top: 70px;
}
#menu{
    height: 70px; 
	width: 100%;
	padding: 0 30px;
	position: fixed;
	top: 0;
	right:0;
	background: #000000 !important;
	z-index: 999;

	img.logo{
		padding: 0;
		height: 70px;
		width: auto;
		float: left;
	}

	ul.nav	{
		margin: 25px 0 0 0;
		text-transform: uppercase;

		li:hover>ul{
			z-index: 55555555;
			position: absolute;
			display: block;
		}

		ul{
			display: none;
			list-style: none;
			margin: 0px;
			float: left;
			background-color: #ffffff;
			padding: 15px 25px;

			&:before{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 15px 15px 15px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				top: -15px;
				left: 10%;
			}

			>li{
				float: left;
				padding: 5px 0px;
				>a{
					font-family: $roboto-light;
					font-size: 16px;
					color: #4e4e4e;
					text-transform: uppercase;
					min-width: 200px;
					display: block;

					&:hover{
						color: $gold;
						text-decoration: none;
					}
				}
			}
		}
	}

	.navbar-nav>li {
		>a	{
			font-family: $roboto-light;
			font-size: 16px;
			color: #ffffff;
			text-transform: uppercase;

			margin: 0px 5px 0 5px;
			padding: 15px 15px;
			text-align: left;

			-webkit-transition: all 0.5s; /* Safari */
    		transition: all 0.5s;

    		@media only screen and (max-width : 1200px) {
    			margin: 0px 5px 0 5px;
				padding: 15px 5px;
    		}
			

			&:hover{
				color: $gold;
			}

		}

		&:last-child{
			>a{
				border: 2px solid #ffffff;
			}
		}
	}
}


/* #Responsive meny
================================================== */

.toggle-id-1 {
	color: white;
	font-size: 38px;
	height: 50px;
	width: 50px;
	position: absolute;
	top: 10px;
	display: inline-block;
	right: 1px;

	p{
		color: #ffffff;
		font-size: 20px;
		line-height: 50px;
		position: absolute;
		top: 3px;
		display: inline-block;
		right: 61px;
		font-family: $roboto-medium;
	}
}

.close-menu {
	color: white;
	font-size: 34px !important;
	float: right;
	margin-top: 15px;
	margin-right: 20px;
}

.responsive-menu-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	overflow-y: scroll;
	background: #000000;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	left: -100vw;
	z-index: 9999;
	opacity: 0;
	padding-left: 0px;
}

.responsive-menu-container::-webkit-scrollbar { width: 0 !important }

.responsiveMenuImage {
	padding: 15px 0 15px 20px;
	height: 70px;
}

.responsiveMenu a:hover, .responsiveMenu .current_page_item{
	text-decoration: none;
	color: $gold;
}

.responsiveMenu {
	list-style-type: none;
	margin-left: 0px;
	padding-left: 0px;
	margin-top: 50px;
}

.responsiveMenu li a{
	color: white;
	display: block;
	font-size: 34px;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;
}

.responsiveMenu li ul{
	list-style: none;
	padding: 0;

	li > a{
		font-size: 16px;
	}
}

.responsiveMenu li a:hover { 
	text-decoration: none;
}

.responsiveMenu ._mi {
	border-right: 0px;
}

.responsive-menu-container{
	p.contact{
		color: #ffffff;
		font-family: $roboto-light;
		text-align: center;
		font-size: 18px;
		line-height: 40px;
		margin-top: 50px;

		a{
			color:#ffffff;

			&:hover{
				color:#ffffff;
			}
		}

			img{
				width: 32px;
				heigth: auto;
				display: inline-block;
				margin-right: 10px;
				margin-left: 20px;
			}
	}
}

/* ================================================== */

