.jumbotron {
	background-color: transparent;
	min-height: 100vh;
	padding: 0px;
	margin: 0px;
	display: table;
	width: 100%;

	.jumbotron-content {
		display: table-cell;
		vertical-align: middle;
		padding-top: 80px;
		padding-bottom: 80px;

		h1{
			font-size: 80px;
			font-family: $butler-bold;
			text-align: center;
			color: #ffffff;
		}
	}
}

.grid-boxar{
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}

	.grid-box{
		width: 50%;
		float:left;

		@media only screen and (max-width : 1200px) {
			width: 100%;
		}
	}

	.grid-pic{
		img{
			width: 100%;
			heigth: auto;
		}
	}

	.grid-text{
		display: table;

		.grid-text-content{
			display: table-cell;
			vertical-align: middle;
			padding-top: 80px;
			padding-bottom: 80px;
			padding-left: 15px;
			padding-right: 15px;

			@media only screen and (min-width : 1200px) {
				padding-left: 20%;
				padding-right: 20%;
			}

			h2{
				font-size: 46px;
				font-family: $butler-bold;
				text-align: left;
				color: #000000;
				position: relative;
				margin-bottom: 50px;

				@media only screen and (max-width : 993px) {
					font-size: 45px;
				}


				&:after{
					content: "";
				    width: 260px;
				    height: 6px;
				    background: $gold;
				    bottom: -20px;
				    left: 0%;
				    margin-left: 0px;
				    position: absolute;
				}
			}

			p{
				font-size: 20px;
				font-family: $roboto-light;
				text-align: left;
				color: #000000;
				line-height: 1.6;
				letter-spacing: 0.5px;

				b{
					font-family: $roboto-bold;
				}
			}

			.button{
				font-size: 17px;
				font-family: $roboto-medium;
				text-align: left;
				color: #ffffff;
				background-color: #000000;
				padding: 15px 25px;
				text-transform: uppercase;
				display: inline-block;
				margin-top: 25px;
			}
		}
	}
}




// Meny

#meny-suggestion{
	background: #f3f3f3;
	padding-top: 120px;
	padding-bottom: 140px;

	h2{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: center;
		color: #000000;
		position: relative;
		margin-bottom: 50px;

		&:after{
			content: "";
			width: 260px;
			height: 6px;
			background: $gold;
			bottom: -20px;
			left: 50%;
			margin-left: -130px;
			position: absolute;
		}
	}

	p{
		font-size: 20px;
		font-family: $roboto-light;
		text-align: left;
		color: #000000;
		margin-top: 30px;

		b{
			font-family: $roboto-bold;
		}
	}

	.embed-container { 
		position: relative; 
		padding-bottom: 56.25%; 
		height: 0; 
		overflow: hidden; 
		max-width: 100%; 
		margin-top: 30px;
	} 

	.embed-container iframe, .embed-container object, .embed-container embed { 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}
}


#meny-meny{
	padding-top: 90px;
	padding-bottom: 110px;

	h2{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: left;
		color: #000000;
		position: relative;
		margin-bottom: 100px;

		&:after{
			content: "";
			width: 150px;
			height: 6px;
			background: $gold;
			bottom: -20px;
			left: 0%;
			margin-left: -0px;
			position: absolute;
		}
	}

	h3{
		font-size: 28px;
		font-family: $butler-bold;
		text-align: left;
		color: #000000;
		margin-top: 50px;
	}

	p{
		font-size: 20px;
		font-family: $roboto-light;
		text-align: left;
		color: #000000;

		b{
			font-family: $roboto-bold;
		}
	}

	.gold-box{
		background: #ffd7af;
		padding: 50px;
		margin-top: 50px;

		p{
			font-size: 18px;
			font-family: $roboto-light;
			text-align: left;
			color: #000000;
			margin-bottom: 30px;
			margin-top: 0;
		}

		.button{
			font-size: 17px;
			font-family: $roboto-medium;
			text-align: left;
			color: #ffffff;
			background-color: #000000;
			padding: 15px 25px;
			text-transform: uppercase;
			display: inline-block;
		}
	}
}


#meny-tostart{
	background: #eeeeee;
	padding-top: 90px;
	padding-bottom: 90px;

	h3{
		font-size: 28px;
		font-family: $butler-bold;
		text-align: left;
		color: #000000;
	}

	p{
		font-size: 20px;
		font-family: $roboto-light;
		text-align: left;
		color: #000000;
		margin-top: 30px;

		b{
			font-family: $roboto-bold;
		}
	}
}



// Galleri

#gallery{
	.grid-sizer, .grid-item{
		width: 50%;
		margin-bottom: 30px;
		padding: 0 25px;

		@media only screen and (max-width : 992px) {
			width: 100%;
		}

		img{
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}
}




// Om oss

.omoss-textbox{
	background: #f3f3f3;
	padding-top: 120px;
	padding-bottom: 140px;

	h2{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: center;
		color: #000000;
		position: relative;
		margin-bottom: 50px;

		&:after{
			content: "";
			width: 260px;
			height: 6px;
			background: $gold;
			bottom: -20px;
			left: 50%;
			margin-left: -130px;
			position: absolute;
		}
	}

	p{
		font-size: 20px;
		font-family: $roboto-light;
		text-align: center;
		color: #000000;
		margin-top: 50px;

		b{
			font-family: $roboto-bold;
		}
	}

	.button{
			font-size: 17px;
			font-family: $roboto-medium;
			text-align: center;
			color: #ffffff;
			background-color: #000000;
			padding: 15px 25px;
			text-transform: uppercase;
			display: inline-block;
			margin-top: 50px;
	}
}




// Kontakt

.kontakt-textbox1{
	background: #f3f3f3;
	padding-top: 120px;
	padding-bottom: 140px;

	h1{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: center;
		color: #000000;
		position: relative;
		margin-bottom: 50px;

		&:after{
			content: "";
			width: 260px;
			height: 6px;
			background: $gold;
			bottom: -20px;
			left: 50%;
			margin-left: -130px;
			position: absolute;
		}
	}

	p{
		font-size: 24px;
		font-family: $roboto-light;
		text-align: center;
		color: #000000;
		margin-top: 50px;

		@media only screen and (max-width : 768px) {
			font-size: 18px;
		}

		a{
			color:#000000;

			&:hover{
				color:#000000;
			}
		}

		span.contact-info {
		  white-space: nowrap;
		}

		img{
			width: 32px;
			heigth: auto;
			display: inline-block;
			margin-right: 10px;
			margin-left:30px;
		}
	}
}

.kontakt-textbox2{
	background: #ffffff;
	padding-top: 120px;
	padding-bottom: 140px;

	h2{
		font-size: 34px;
		font-family: $butler-bold;
		text-align: center;
		color: #000000;
		position: relative;
		margin-bottom: 50px;
	}

	input, textarea{
		border: 1px solid $gold;
		border-radius: 0px;
		background-color: #ffffff;
		padding: 5px 10px;
		font-size: 19px;
		color: #000000;
		font-family: $roboto-light;
		width: 100%;
		max-width: 100%;
		margin-top: 15px;
	}

	::-webkit-input-placeholder {
		color: #000000;
		font-family: $roboto-light;
	}

	:-moz-placeholder { /* Firefox 18- */
		color: #000000;
		font-family: $roboto-light;
	}

	::-moz-placeholder {  /* Firefox 19+ */
		color: #000000;
		font-family: $roboto-light;
	}

	:-ms-input-placeholder {  
		color: #000000;
		font-family: $roboto-light;
	}

	input[type="submit"] {
		font-size: 17px;
		font-family: $roboto-medium;
		text-align: center;
		color: #ffffff;
		background-color: #000000;
		padding: 15px 25px;
		text-transform: uppercase;
		display: inline-block;
		margin-top: 15px;
		width: auto;
		border: none;
		float: right;
	}
}